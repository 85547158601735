<template>
  <v-row justify="center">
    <v-col cols="12" :lg="8" :class="$vuetify.breakpoint.smAndDown ? 'px-10' : 'px-2'">
      <div class="transaction-page">
        <div>
          <v-row class="px-4" justify="end">
            <v-col class="align" cols="12">
              <label class="font-weight-bold mr-2">{{ $t(`label.searchDate`) }}</label>
              <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
                <v-menu ref="menu" v-model="searchFromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      color="navigator_active"
                      :placeholder="$t(`label.from`)"
                      append-icon="event"
                      outlined
                      class="input-field"
                      hide-details
                      dense
                      readonly
                      v-on="on"
                      v-model="searchFromDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="searchFromDate" scrollable color="navigator_active" @input="searchFromDateMenu = false" :locale="language"></v-date-picker>
                </v-menu>
              </div>
              <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
                <v-menu ref="menu" v-model="searchToDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      color="navigator_active"
                      :placeholder="$t(`label.to`)"
                      append-icon="event"
                      outlined
                      class="input-field"
                      hide-details
                      dense
                      readonly
                      v-on="on"
                      v-model="searchToDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="searchToDate" scrollable color="navigator_active" @input="searchToDateMenu = false" :locale="language"></v-date-picker>
                </v-menu>
              </div>
              <v-btn tile elevation="5" style="border-radius:5px;" class="mx-1" width="40" height="40" color="title_color" @click="searchBonusHistory">
                <v-icon>search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            class="transaction-table px-4"
            :headers="tableHeaders"
            :items="bonusHistoryList"
            :items-per-page="this.pagination.tableRowsCount"
            hide-default-footer
            disable-sort
            :no-data-text="$t(`message.noDataAvaliable`)"
          >
            <template v-slot:item="i">
              <tr>
                <td class="body-2">{{ getBonusTitle(i.item.locale) }}</td>
                <td class="body-2">{{ i.item.refId }}</td>
                <td class="body-2">{{ i.item.bonusApplyDate | longDate }}</td>
                <td class="body-2">{{ i.item.bonusGivenAmount | currency }}</td>
                <td class="body-2">{{ $t(`bonusStatus.${i.item.status.toLowerCase()}`) }}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination v-model="currentPage" :length="totalPage" @input="changePage" :total-visible="paginationPageLimit"></v-pagination>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { SHARED } from '@/constants/constants'
import { locale, uiHelper } from '@/util'
import { BONUS_HISTORY } from '@/store/bonus.module'
export default {
  name: 'history',
  data: () => ({
    language: uiHelper.getLanguage(),
    currentLanguage: uiHelper.getLanguage(),
    searchFromDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10),
    searchFromDateMenu: false,
    searchToDate: new Date().toISOString().substr(0, 10),
    searchToDateMenu: false,
    currentPage: 1,
    totalPage: 1,
    paginationPageLimit: 10,
    pagination: {},
    searchCriteria: {
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE
    },
    tableHeaders: [
      {
        text: locale.getMessage('label.title'),
        align: 'left',
        class: 'body-1'
      },
      {
        text: locale.getMessage('label.transactionId'),
        align: 'left',
        class: 'body-1'
      },
      {
        text: locale.getMessage('label.dateApplied'),
        align: 'left',
        class: 'body-1'
      },
      {
        text: locale.getMessage('label.amount'),
        align: 'left',
        class: 'body-1'
      },
      {
        text: locale.getMessage('label.status'),
        align: 'left',
        class: 'body-1'
      }
    ]
  }),
  computed: {
    bonusHistoryList() {
      return this.$store.state.bonus.historyList
    },
    bonusPagination() {
      return this.$store.state.bonus.pagination
    }
  },
  watch: {
    bonusPagination() {
      this.pagination = {
        pageNumber: this.$store.state.bonus.pagination.pageNumber,
        pageSize: this.$store.state.bonus.pagination.pageSize,
        lastPage: this.$store.state.bonus.pagination.lastPage,
        tableRowsCount: this.$store.state.bonus.pagination.tableRowsCount
      }

      this.currentPage = this.$store.state.bonus.pagination.pageNumber
      this.totalPage = this.$store.state.bonus.pagination.lastPage
    }
  },
  created() {
    this.getPromotionHistory()
  },
  methods: {
    getBonusTitle(bonusLocale) {
      if (bonusLocale.find(x => x.language == this.currentLanguage) != null) {
        return bonusLocale.find(x => x.language == this.currentLanguage).title
      } else if (bonusLocale.find(x => x.language == 'en') != null) {
        bonusLocale.find(x => x.language == 'en').title
      } else {
        return bonusLocale[0].title
      }
    },
    getPromotionHistory() {
      let bonusHistoryObj = {
        overallStatus: 'history',
        fromDate: this.searchFromDate + ' 00:00:00',
        toDate: this.searchToDate + '  23:59:59',
        page: this.searchCriteria.pageNumber,
        itemsPerPage: this.searchCriteria.pageSize
      }
      this.$store.dispatch(`${BONUS_HISTORY}`, { bonusHistoryObj })
    },
    searchBonusHistory() {
      this.searchCriteria.pageNumber = 1
      this.getPromotionHistory()
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      this.getPromotionHistory()
    }
  }
}
</script>

<style lang="scss">
.transaction-page {
  //min-height: 650px;
}
.transaction-status {
  &.complete {
    color: var(--v-primary-base);
  }
  &.failed {
    color: var(--v-secondary_2-base);
  }
  &.pending {
    color: var(--v-base-base);
  }
}

.transaction-page-search-date-field-box {
  width: 160px;
}

/* Bank Table CSS */
.v-data-table__wrapper {
  table {
    .v-data-table-header {
      tr {
        background-color: #2c3482 !important;
        th {
          span {
            color: var(--v-base-base);
          }
        }
      }
    }
    tbody {
      tr:nth-child(2n) {
        background-color: #2d3454 !important;
      }
    }
    td,
    th {
      border-bottom: 0px !important;
    }
  }
}

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;
  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }
    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }
    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}
</style>
